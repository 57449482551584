<template>
  <v-card flat>
    <v-dialog max-width="600px" v-model="showEditPickup">
      <v-card v-if="pickup">
        <v-card-title>{{ formatAddress(pickup) }}</v-card-title>
        <v-card-text>
          key={{ pickupKey }}
          <v-text-field
            label="Line 1"
            v-model="pickup.line1"
          />
          <v-select
            label="City"
            v-model="pickup.city"
            :items="Object.keys(cities)"
          />
          <v-text-field
            label="Postcode"
            v-model="pickup.postal_code"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showEditPickup=false">Close</v-btn>
          <template v-if="!readOnly">
            <v-spacer/>
            <v-btn color="error" @click="confirmDeletePickup(pickupKey,pickup)">Delete</v-btn>
            <v-spacer/>
            <v-btn color="success" @click="savePickup(pickupKey, pickup)">Save</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600px" v-model="showEdit">
      <v-card>
        <v-card-title>{{ city }}</v-card-title>
        <v-card-text v-if="rates">
          {{ formatCurrency(rates.price_flex) }} Regular Rate <br/>
          {{ formatCurrency(rates.price_company) }} Corporate Rate <br/>
          <v-text-field
            label="Regular Shipping Rate"
            v-model="rates.price_flex"
          />
          <v-text-field
            label="Corporate Shipping Rate"
            v-model="rates.price_company"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showEdit=false">Close</v-btn>
          <template v-if="!readOnly">
            <v-spacer/>
            <v-btn color="error" @click="confirmDelete(city)">Delete</v-btn>
            <v-spacer/>
            <v-btn color="success" @click="save(city,rates)">Save</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="tabs" centered>
      <v-tab>Pickup Locations</v-tab>
      <v-tab>City Rates</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card-title>
          Pickup Locations
          <v-spacer/>
          <v-btn outlined @click="createNewPickup">New Pickup Location</v-btn>
        </v-card-title>
        <v-card-text>
          <p>
            Any address that exactly matches the line1, city and postal code will be considered a 'free' pickup
            location.
          </p>
          <b>
            Note: customer address must match exactly (capital letters do not matter, but spaces and punctuation does)
          </b>
          <br/><br/><br/>
          <v-row v-for="(location,key) of pickupLocations" v-bind:key="key">
            <v-col style="font-size: large">
              {{ formatAddress(location) }}
            </v-col>
            <v-col cols="1">
              <v-btn small outlined @click="editPickup(key,location)">Edit</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-title>
          City Shipping Rates
          <v-spacer/>
          <v-btn outlined @click="createNewPickup">New Pickup Location</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row v-for="(rates,city) of cities" v-bind:key="city">
            <v-col cols="2">{{ city }}</v-col>
            <v-col>
              {{ formatCurrency(rates.price_flex) }} Regular Rate <br/>
              {{ formatCurrency(rates.price_company) }} Corporate Rate <br/>
              <!--          {{rates}}-->
            </v-col>
            <v-col cols="1">
              <v-btn outlined small @click="editCity(city,rates) ">Edit</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>


</template>

<script>
import api from "@/api";
import {deepCopy, formatAddress, formatCurrency} from "../store/utils";
import urlState from "@/router/urlState";

export default {
  name: "ShippingRules",
  mixins: [urlState],
  data() {
    return {
      cities: null,
      showEdit: null,
      city: null,
      rates: null,
      pickupLocations: null,
      pickup: null,
      showEditPickup: null,
      pickupKey: null,
      tabs: null
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'tabs', urlParam: 't', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    return this.fetchData();
  },
  methods: {
    formatAddress,
    formatCurrency,
    async fetchData() {
      this.cities = await api.get('v2/shipping').then(({data}) => data);
      this.pickupLocations = await api.get('v2/shipping/pickup-locations').then(({data}) => data);
    },
    editCity(city, rates) {
      this.showEdit = true;
      this.city = deepCopy(city);
      this.rates = deepCopy(rates);
    },
    async save(key, rates) {
      const result = await api.put(`v2/shipping/${key}`, rates)
        .then(({data}) => data);
      console.log('saved', result);
      this.showEdit = false;
      return this.fetchData();
    },
    async confirmDelete(key) {
      if (confirm('are you sure you want to delete ' + key)) {
        await api.delete(`v2/shipping/${key}`);
        this.showEdit = false;
        return this.fetchData();
      }
    },
    createNewPickup() {
      this.showEditPickup = true;
      this.pickupKey = window.crypto.randomUUID();
      this.pickup = {
        line1: '',
        city: '',
        postal_code: ''
      }
    },
    editPickup(key, location) {
      this.showEditPickup = true;
      this.pickup = deepCopy(location);
      this.pickupKey = deepCopy(key);
    },
    async savePickup(key, pickup) {
      const result = await api.put(`v2/shipping/pickup-locations/${key}`, pickup)
        .then(({data}) => data);
      console.log('saved', result);
      this.showEditPickup = false;
      return this.fetchData();
    },
    async confirmDeletePickup(key, pickup) {
      if (confirm('are you sure you want to delete ' + formatAddress(pickup))) {
        await api.delete(`v2/shipping/pickup-locations/${key}`);
        this.showEditPickup = false;
        return this.fetchData();
      }
    },

  },
  props: {
    readOnly: {type: Boolean, default: false, required: false}
  }
}
</script>


<style scoped>

</style>